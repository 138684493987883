import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Button } from 'sw-ui';

import { OperationDetailVat } from './OperationDetailVat';

import SERVICETYPE from '../../../../../../../../bi/constants/serviceType';

const MAX_COUNT = {
  AIR: 2,
  TRAIN: 1,
};

const LABELS = {
  BUTTONS: { ADD_VAT: 'Добавить НДС' },
  TITLE: 'НДС',
};

export const OperationDetailVats =
  ({
    price,
    serviceType,
    onSetPrice,
    onBlur,
  }) => {
    const [innerVats, setVats] = useState([]);
    const buttonIsDisabled = serviceType === SERVICETYPE.TRAIN && innerVats.length === 1;

    useEffect(() => {
      const currentVats = price.vats.map((vat, index) => ({
        amount: vat.Amount,
        rate: vat.Rate,
        innerId: index,
      }));

      setVats(currentVats);
    }, []);

    useEffect(() => {
      onSetPrice({
        ...price,
        vats: innerVats,
      });
    }, [innerVats]);

    const removeVat = (innedId) => setVats(innerVats.filter((item) => item.innerId !== innedId));

    const addVat = () => setVats([
      ...innerVats,
      {
        rate: 0, amount: 0, innerId: innerVats.length + 1,
      },
    ]);

    const getMaxCount = () => {
      if (serviceType === SERVICETYPE.TRAIN) {
        return MAX_COUNT.TRAIN;
      }

      return MAX_COUNT.AIR;
    };

    const updateVat = (vat) => setVats(innerVats
      .map((innerVat) => (innerVat.innerId === vat.innerId ? vat : innerVat)));

    const renderVat = () => innerVats.map((vat) => (
      <OperationDetailVat
        key={ vat.innerId }
        vat={ vat }
        onRemoveVat={ removeVat }
        onUpdateVat={ updateVat }
        onBlur={ onBlur }
        removeButtonDisabled={ buttonIsDisabled }
      />
    ));

    const renderAddVatButton = () => (
      <Button
        label={ LABELS.BUTTONS.ADD_VAT }
        onClick={ () => addVat() }
        disabled={ innerVats.length === getMaxCount() }
      />
    );

    return (
      <div>
        <p>{ LABELS.TITLE }</p>
        { renderVat() }
        { renderAddVatButton() }
      </div>
    );
  };

OperationDetailVats.propTypes = {
  price: PropTypes.object.isRequired,
  serviceType: PropTypes.string.isRequired,
  onSetPrice: PropTypes.func.isRequired,
  onBlur: PropTypes.func.isRequired,
};
