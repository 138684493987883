import React, {
  useCallback, useEffect, useState,
} from 'react';
import PropTypes from 'prop-types';
import { Button } from 'sw-ui';

import { ChangeTicketNumberModal, ChangeTicketPriceModal } from './components';
import { VersionItemChange } from './VersionItemChange';
import PriceComponent from '../air/components/Price';
import Vat from '../air/components/Vat';

import COMPONENTS from '../../../../bi/constants/components';
import { STATUSCODES } from '../../../../bi/constants/trips';
import { SERVICETYPE } from '../../../../bi/constants/serviceType';

import styles from '../../styles/trip.module.scss';

const EVENT_DATE = 'eventDate';
const TICKET_NUMBER = 'ticketNumber';
const CLOSING_PERIOD_TICKET_NUMBER = 'closingPeriodTicketNumber';
const PRICE = 'price';
const CLOSING_PERIOD_PRICE = 'closingPeriodPrice';

const LABELS = {
  CREATEDORDER: 'Создан заказ',
  CONFIRMEDORDER: 'Заказ подтвержден',
  CLOSE: 'ЗАКРЫТЬ',
  CHANGE_ORDER: 'Изменение заказа:',
  FINANCIAL_OPERATION: 'Финансовые операции',
};

const VersionItem = ({
  tripItem, tripItemInd, opts, tripItems,
}) => {
  const [closingDocument, setClosingDocument] = useState(null);
  const [showChangeTicketNumberModalDialog, setShowChangeTicketNumberModalDialog] = useState(false);
  const [showChangeTicketPriceModalDialog, setShowChangeTicketPriceModalDialog] = useState(false);
  const [operations, setOperations] = useState([]);

  const {
    versionButtons,
    flagEditTripItemVersion,
    reloadPage,
    onGetAirlaneTicketClosing,
    onAirTripFieldValidationAir,
    onChangeTicketNumber,
    onTripPriceFieldValidation,
    onGetOperationsByVersionIds,
    onUpdatePriceByVersion,
  } = opts;

  const button = versionButtons.length > 0 && versionButtons.find(({ id }) => id === tripItem.Id);

  const preparedChanges = ({
    Status,
    Price,
    CreatedBy,
    EventDate,
    ServiceType,
    Id,
    Penalties,
    ReturnFunds,
    Surcharges,
    Description,
    JsonData,
    AdditionalServicesPrice,
    AdditionalServiceLength,
    AdditionalServicesDescription,
  }) => {
    const changes = [];
    const idDetails = ServiceType === SERVICETYPE.AIR || SERVICETYPE.TRAIN ? Id : null;

    switch (Status) {
      case STATUSCODES.CREATE: {
        changes.push({
          description: LABELS.CREATEDORDER,
          price: Price,
          user: CreatedBy,
          eventDate: EventDate,
          idDetails,
        });

        break;
      }
      case STATUSCODES.HIDDEN: {
        changes.push({
          description: LABELS.CONFIRMEDORDER,
          price: Price,
          user: CreatedBy,
          eventDate: EventDate,
          idDetails,
        });

        break;
      }
      case STATUSCODES.BOOK:
      case STATUSCODES.CANCEL: {
        const json = JSON.parse(JsonData);
        const AdditionalServices = json.AdditionalServices;

        if (Penalties && Penalties.length) {
          changes.push({
            description: Penalties[0].Description,
            price: Penalties[0].Amount,
            base: Penalties[0].Base,
            commission: Penalties[0].Commission,
            user: CreatedBy,
            eventDate: EventDate,
            idDetails,
          });
        }

        if (ReturnFunds && ReturnFunds.length) {
          changes.push({
            description: ReturnFunds[0].Description,
            price: ReturnFunds[0].Amount,
            base: ReturnFunds[0].Base,
            commission: ReturnFunds[0].Commission,
            hideExtraDeleteButton: Penalties && Penalties.length,
            user: CreatedBy,
            eventDate: EventDate,
            idDetails: !Penalties || !Penalties.length ? idDetails : null,
          });
        }

        if (Surcharges && Surcharges.length) {
          changes.push({
            description: Surcharges[0].Description,
            price: Surcharges[0].Amount,
            base: Surcharges[0].Base,
            commission: Surcharges[0].Commission,
            hideExtraDeleteButton: Penalties && Penalties.length,
            user: CreatedBy,
            eventDate: EventDate,
            idDetails,
          });
        }

        if (changes.length === 0
          && AdditionalServices
          && AdditionalServices.length
          && ServiceType !== SERVICETYPE.VIP_HALL) {
          changes.push({
            description: (
              <span>
                { LABELS.CHANGE_ORDER }
                <br />
                { ' ' }
                { Description }
                { ' ' }
                { AdditionalServiceLength > 1 && AdditionalServicesDescription }
              </span>
            ),
            price: AdditionalServicesPrice,
            user: CreatedBy,
            eventDate: EventDate,
            idDetails,
          });
        }

        if (changes.length === 0) {
          changes.push({
            description: (
              <span>
                { LABELS.CHANGE_ORDER }
                <br />
                { ' ' }
                { Description }
              </span>
            ),
            price: 0,
            user: CreatedBy,
            eventDate: EventDate,
            idDetails,
          });
        }

        break;
      }
      case STATUSCODES.METADATA: {
        changes.push({ price: Price });

        break;
      }
    }

    return changes;
  };

  const renderPriceDetails = () => {
    const {
      JsonData, Id, ProviderName, VatDetails,
    } = tripItem;
    const details = JSON.parse(JsonData).PriceDetails;
    const itemVat = {
      VatDetails,
      ProviderName,
    };

    return (
      <div className={ styles.full_price_wrap }>
        <div className={ styles.full_price_header }>
          <h4>{ LABELS.FINANCIAL_OPERATION }</h4>
          <div>
            <Button
              label={ LABELS.CLOSE }
              theme={ COMPONENTS.BUTTON.THEME.FLAT }
              onClick={ () => opts.onUpdateVersionButtons(Id) }
            />
          </div>
        </div>
        <PriceComponent
          isEditing
          priceDetails={ details }
          onChangeInput={ () => {} }
        />
        <Vat
          isEditing
          airline={ tripItem }
          keyItem={ 0 }
          ticket={ itemVat }
          handleChangeVatDetails={ () => {} }
        />
      </div>
    );
  };

  const getFlagInFlagList = (flagName) => flagEditTripItemVersion.find((flag) => flag === flagName);
  const versionId = tripItem && tripItem.Id;
  const isAirTicket = tripItem && tripItem.ServiceType === SERVICETYPE.AIR;
  const isTrainTicket = tripItem && tripItem.ServiceType === SERVICETYPE.TRAIN;
  const lastNumberTicket = isAirTicket ? JSON.parse(tripItems[0].JsonData).TicketsExtended[0].Num : null;
  const isSoloVersion = tripItems.length === 1;

  useEffect(() => {
    const getClosingDocument = async () => {
      const closingDocumentData = await onGetAirlaneTicketClosing(versionId);

      setClosingDocument(closingDocumentData.Packages);
    };

    const getOperations = async () => setOperations(await onGetOperationsByVersionIds([versionId]));

    getClosingDocument();
    getOperations();
  }, []);

  const memoizedSetShowChangeTicketNumberModalDialog = useCallback((value) => {
    setShowChangeTicketNumberModalDialog(value);
  }, []);

  const memoizedSetShowChangeTicketPriceModalDialog = useCallback((value) => {
    setShowChangeTicketPriceModalDialog(value);
  }, []);

  const renderTripItems = () => tripItems.map((item) => preparedChanges(item));

  const renderFullPrice = () => {
    if (button && button.fullPrice) {
      return renderPriceDetails(tripItem);
    }

    return null;
  };

  const renderButtonChangeTicketNumber = () => {
    if (!isAirTicket) return null;

    return (
      <ChangeTicketNumberModal
        versionId={ versionId }
        reloadPage={ reloadPage }
        onAirTripFieldValidationAir={ onAirTripFieldValidationAir }
        onChangeTicketNumber={ onChangeTicketNumber }
        showModalDialog={ showChangeTicketNumberModalDialog }
        closingDocument={ closingDocument }
        lastNumberTicket={ lastNumberTicket }
        preparedTripItems={ renderTripItems() }
        setShowModalDialog={ memoizedSetShowChangeTicketNumberModalDialog }
        isFlagEditTicketNumberClosingPeriod={ !!getFlagInFlagList(CLOSING_PERIOD_TICKET_NUMBER) }
      />
    );
  };

  const renderButtonChangePrice = () => {
    if (!(isAirTicket || isTrainTicket) && !isSoloVersion) {
      return null;
    }

    return (
      <ChangeTicketPriceModal
        operations={ operations }
        version={ tripItem }
        showModalDialog={ showChangeTicketPriceModalDialog }
        closingDocument={ closingDocument }
        onValidation={ onTripPriceFieldValidation }
        onUpdatePrice={ onUpdatePriceByVersion }
        onSetShowModalDialog={ memoizedSetShowChangeTicketPriceModalDialog }
      />
    );
  };

  const changesHtml = renderTripItems()[tripItemInd].map((change, changeInd) => (
    <VersionItemChange
      versionItem={ change }
      opts={ opts }
      tripItem={ tripItem }
      tripItems={ tripItems }
      tripItemInd={ tripItemInd }
      key={ changeInd }
      closingDocument={ closingDocument }
      setShowChangeTicketNumberModalDialog={ setShowChangeTicketNumberModalDialog }
      setShowChangeTicketPriceModalDialog={ setShowChangeTicketPriceModalDialog }
      isFlagEditTripItemVersion={ !!getFlagInFlagList(EVENT_DATE) }
      isFlagEditTicketNumberCurrentPeriod={ !!getFlagInFlagList(TICKET_NUMBER) }
      isFlagEditTicketNumberClosingPeriod={ !!getFlagInFlagList(CLOSING_PERIOD_TICKET_NUMBER) }
      isFlagEditTicketPrice={ !!getFlagInFlagList(PRICE) || !!getFlagInFlagList(CLOSING_PERIOD_PRICE) }
    />
  ));

  return (
    <div className={ styles.item }>
      { renderButtonChangeTicketNumber() }
      { renderButtonChangePrice() }
      { changesHtml }
      { renderFullPrice() }
    </div>
  );
};

VersionItem.propTypes = {
  tripItems: PropTypes.array.isRequired,
  opts: PropTypes.object.isRequired,
  tripItem: PropTypes.object.isRequired,
  tripItemInd: PropTypes.number.isRequired,
};

export { VersionItem };
