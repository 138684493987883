import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import { OperationDetailPrice } from '../OperationDetailsPrice/OperationDetailPrice';
import { OperationDetailVats } from '../OperationDetailVat/OperationDetailVats';

import styles from '../../styles.module.css';

const LABELS = { OPERATION_DETAIL: 'Деталь операции' };

export const OperationDetail =
  ({
    detail,
    onSetUpdateDetails,
    onValidate,
    serviceType,
    onSetErrors,
  }) => {
    const [price, setPrice] = useState({
      id: detail.id,
      description: detail.description,
      base: detail.Base,
      commission: detail.Commission,
      fee: detail.Fee,
      tax: detail.Tax,
      taxes: detail.Taxes,
      vats: detail.vats,
      fare: detail.Fare,
      tariffPrice: detail.TariffPrice,
      providerCommission: detail.providerCommission,
    });

    useEffect(() => {
      onSetUpdateDetails([price]);
    }, [price]);

    const handleChangeVersionPriceDetailsInput = ({ target: { value } }, field) => {
      const currentValue = value === '-' || value === '' ? value : Number(value);

      setPrice({
        ...price,
        [field]: currentValue,
      });

      const err = onValidate(price);

      onSetErrors(!!err ? [err] : []);
    };

    const handleOnBlur = () => {
      const err = onValidate(price);
      onSetErrors(!!err ? [err] : []);
    };

    return (
      <div className={ styles.operation_detail }>
        <p className={ styles.title }>{ `${LABELS.OPERATION_DETAIL}: ${detail.id}, ${detail.description}` }</p>
        <div className={ styles.operation_detail_wrapper }>
          <OperationDetailPrice
            price={ price }
            onChangeVersionPriceDetailsInput={ handleChangeVersionPriceDetailsInput }
            onBlur={ handleOnBlur }
            serviceType={ serviceType }
          />
          <div className={ styles.vat_wrapper }>
            <OperationDetailVats
              price={ price }
              onSetPrice={ setPrice }
              onBlur={ handleOnBlur }
              serviceType={ serviceType }
            />
          </div>
        </div>
      </div>
    );
  };

OperationDetail.propTypes = {
  detail: PropTypes.object.isRequired,
  onSetUpdateDetails: PropTypes.func.isRequired,
  onValidate: PropTypes.func.isRequired,
  serviceType: PropTypes.string.isRequired,
  onSetErrors: PropTypes.func.isRequired,
};
