import React from 'react';
import PropTypes from 'prop-types';
import { Button } from 'sw-ui';

import Input from '../../../../../../../../components/input';

import COMPONENTS from '../../../../../../../../bi/constants/components';
import { VAT_PRICE_FIELDS } from '../../../../../../../../bi/constants/vat';

import styles from './styles.module.css';

const LABELS = {
  DETAIL_VAT: {
    RATE: 'Процент',
    AMOUNT: 'Сумма НДС',
  },
  BUTTONS: { REMOVE_VAT: 'x' },
};

export const OperationDetailVat =
  ({
    vat,
    onRemoveVat,
    onUpdateVat,
    onBlur,
    removeButtonDisabled,
  }) => {
    const handleChangeVersionPriceDetailsInput = ({ target: { value } }, field) => {
      const currentValue = value === '-' || value === '' ? value : Number(value);

      const updatedVat = {
        ...vat,
        [field]: currentValue,
      };

      onUpdateVat(updatedVat);
    };

    return (
      <div className={ styles.vat }>
        <Input
          field={ VAT_PRICE_FIELDS.RATE }
          label={ LABELS.DETAIL_VAT.RATE }
          value={ vat.rate }
          type={ COMPONENTS.INPUT.TYPE.NUMBER }
          onBlur={ onBlur }
          onChange={ handleChangeVersionPriceDetailsInput }
        />
        <Input
          field={ VAT_PRICE_FIELDS.AMOUNT }
          label={ LABELS.DETAIL_VAT.AMOUNT }
          type={ COMPONENTS.INPUT.TYPE.NUMBER }
          value={ vat.amount }
          onBlur={ onBlur }
          onChange={ handleChangeVersionPriceDetailsInput }
        />
        <Button
          className={ styles.add_button }
          label={ LABELS.BUTTONS.REMOVE_VAT }
          onClick={ () => onRemoveVat(vat.innerId) }
          disabled={ removeButtonDisabled }
        />
      </div>
    );
  };

OperationDetailVat.propTypes = {
  vat: PropTypes.object.isRequired,
  onRemoveVat: PropTypes.func.isRequired,
  onUpdateVat: PropTypes.func.isRequired,
  onBlur: PropTypes.func.isRequired,
  removeButtonDisabled: PropTypes.bool.isRequired,
};
