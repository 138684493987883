import React, {
  memo, useCallback, useState,
} from 'react';
import PropTypes from 'prop-types';
import { Button } from 'sw-ui';

import ModalDialog from '../../../../../../components/dialog';
import AjaxButton from '../../../../../../components/ajaxButton';
import { OperationDetail } from './components/OperationDetail/OperationDetail';

import COMPONENTS from '../../../../../../bi/constants/components';
import SERVICETYPE from '../../../../../../bi/constants/serviceType';
import { TRAIN_PRICE_FIELDS_LIST } from '../../../../../../bi/constants/train';
import { AIR_PRICE_FIELDS_LIST } from '../../../../../../bi/constants/airline';

import styles from './styles.module.css';

const { BUTTON: { THEME: { SECOND, MAIN } } } = COMPONENTS;

const LABELS = {
  BUTTONS: {
    CONFIRM: 'Подтвердить',
    CANCEL: 'Отмена',
    CHANGE_IT_ANYWAY: 'Все равно изменить',
  },
  TITLE: 'Детали операции для версии',
  WARNINGS: {
    CLOSING_DOCUMENT_WARN: 'Изменение цен в версии создания невозможна, она учтена в ЗД',
    APPLY_CHANGES_WARN: 'Вы подтверждаете внесение изменений в билет?',
  },
};

const WARNING_STATES = {
  NORMAL: Symbol(),
  WARN: Symbol(),
};

export const ChangeTicketPriceModal = memo(
  ({
    operations,
    version,
    showModalDialog,
    closingDocument,
    onSetShowModalDialog,
    onValidation,
    onUpdatePrice,
  }) => {
    const [updateDetails, setUpdateDetails] = useState([]);
    const [errors, setErrors] = useState([]);
    const [warning, setWarning] = useState('');
    const [warningState, setWarningState] = useState(WARNING_STATES.NORMAL);
    const [loading, setLoading] = useState(false);

    const clearWarning = useCallback(() => setWarning(''), []);

    const clearErrors = useCallback(() => setErrors([]), []);

    const detailInfo = (operations) => operations
      .flatMap((o) => o.details)
      .flatMap((d) => ({ id: d.id, description: d.description }));

    const getFieldList = () => {
      return version.ServiceType === SERVICETYPE.TRAIN
        ? TRAIN_PRICE_FIELDS_LIST
        : AIR_PRICE_FIELDS_LIST;
    };

    const handleValidate = (price) => Object
      .entries(price)
      .reduce((acc, [k, v]) => acc || (getFieldList().includes(k) && onValidation(k, v)), false);

    const formValidation = (validationDetails) => Array
      .of(validationDetails)
      .map((detail) => handleValidate(detail))
      .filter(Boolean);

    const changeWarningState = () => {
      const message = closingDocument?.length > 0
        ? LABELS.WARNINGS.CLOSING_DOCUMENT_WARN
        : LABELS.WARNINGS.APPLY_CHANGES_WARN;

      setWarning(message);
      setWarningState(WARNING_STATES.WARN);
    };

    const preConfirmActions = () => {
      clearWarning();
      clearErrors();
      setLoading(true);
    };

    const confirmChanges = () => {
      const validationErrors = updateDetails.flatMap(formValidation);

      if (validationErrors.length !== 0) {
        setErrors([...validationErrors]);

        return;
      }

      if (warningState === WARNING_STATES.NORMAL) {
        changeWarningState();

        return;
      }

      if (version.ServiceType === SERVICETYPE.TRAIN) {
        updateDetails.map((d) => d.base = d.fare + d.tariffPrice);
      }

      preConfirmActions();

      onUpdatePrice(version.Id, {
        OperationDetails: updateDetails.map((d) => ({
          ...d,
          OperationDetailsId: d.id,
        })),
      })
        .then(() => {
          onSetShowModalDialog(false);
          window.location.reload();
        })
        .catch((err) => setErrors([err.body.Error]))
        .finally(() => setLoading(false));
    };

    const renderError = () => {
      if (errors.length === 0) {
        return null;
      }

      return (
        <div className={ styles.error }>
          { errors[0] }
        </div>
      );
    };

    const renderWarning = () => {
      if (!warning) {
        return null;
      }

      return (
        <div className={ styles.warn }>
          { warning }
        </div>
      );
    };

    const renderConfirmButton = () => {
      const label = warningState === WARNING_STATES.WARN
        ? LABELS.BUTTONS.CONFIRM
        : LABELS.BUTTONS.CHANGE_IT_ANYWAY;

      return (
        <AjaxButton
          onClick={ confirmChanges }
          label={ label }
          theme={ MAIN }
          disabled={ errors.length !== 0 }
          className={ styles['col-1-3'] }
          loading={ loading }
        />
      );
    };

    const renderOperationDetail = () => {
      if (!(version.ServiceType === SERVICETYPE.AIR || version.ServiceType === SERVICETYPE.TRAIN)) {
        return null;
      }

      const jsonData = JSON.parse(version.JsonData);
      const priceDetails = jsonData.PriceDetails;
      const vatDetails = jsonData.VatDetails;
      const detailsInfo = detailInfo(operations);
      const detailId = detailsInfo.length === 0 ? 0 : detailsInfo[0].id;
      const detailDescription = detailsInfo.length === 0 ? '' : detailsInfo[0].description;

      const detail = version.ServiceType === SERVICETYPE.AIR
        ? {
          ...priceDetails,
          id: detailId,
          description: detailDescription,
          vats: vatDetails,
        }
        : {
          ...priceDetails,
          ...jsonData.TrainPricing,
          id: detailId,
          description: detailDescription,
          providerCommission: jsonData.ProviderComission,
          vats: [{ Rate: jsonData.TrainPricing.ServiceTvaRate, Amount: jsonData.TrainPricing.ServiceTva }],
        };

      return (
        <OperationDetail
          onSetUpdateDetails={ setUpdateDetails }
          updateDetails={ updateDetails }
          detail={ detail }
          onValidate={ handleValidate }
          serviceType={ version.ServiceType }
          onSetErrors={ setErrors }
        />
      );
    };

    return (
      <ModalDialog
        show={ showModalDialog }
        onClose={ () => onSetShowModalDialog(false) }
      >
        <div className={ styles.modal }>
          <p className={ styles.title }>{ `${LABELS.TITLE} ${version.Id}:` }</p>
        </div>
        { renderOperationDetail() }
        { renderWarning() }
        { renderError() }
        <div className={ styles.buttons_section }>
          <Button
            theme={ SECOND }
            label={ LABELS.BUTTONS.CANCEL }
            onClick={ () => onSetShowModalDialog(false) }
          />
          { renderConfirmButton() }
        </div>
      </ModalDialog>
    );
  },
);

ChangeTicketPriceModal.propTypes = {
  version: PropTypes.object.isRequired,
  showModalDialog: PropTypes.bool.isRequired,
  onSetShowModalDialog: PropTypes.func.isRequired,
  operations: PropTypes.array.isRequired,
  onValidation: PropTypes.func.isRequired,
  onUpdatePrice: PropTypes.func.isRequired,
  closingDocument: PropTypes.array,
};

ChangeTicketPriceModal.defaultProps = {
  operations: [],
  closingDocument: [],
};
