import React from 'react';
import PropTypes from 'prop-types';

import Input from '../../../../../../../../components/input';

import COMPONENTS from '../../../../../../../../bi/constants/components';
import { SERVICETYPE } from '../../../../../../../../bi/constants/serviceType';
import { AIR_PRICE_FIELDS } from '../../../../../../../../bi/constants/airline';
import { TRAIN_PRICE_FIELDS } from '../../../../../../../../bi/constants/train';

import styles from './styles.module.css';

const LABELS = {
  DETAIL_PRICE: {
    BASE: 'Тариф',
    TAX: 'Таксы авиакомпании',
    TAXES: 'Сбор при оформлении билета (валютный или за группу)',
    COMMISSION: 'Сбор SW',
    FEE: 'Добор сбора',
    FARE: 'Тариф (Fare)',
    TARIFF_PRICE: 'Тариф (Tariff price)',
    PROVIDER_COMMISSION: 'Сбор РЖД',
  },
  TITLE: 'Цена',
};

export const OperationDetailPrice =
  ({
    price,
    onChangeVersionPriceDetailsInput,
    serviceType,
    onBlur,
  }) => {
    const renderAirlinePriceDetails = () => (
      <div className={ styles.price }>
        <p>{ LABELS.TITLE }</p>
        <div className={ styles.price_row }>
          <Input
            label={ LABELS.DETAIL_PRICE.BASE }
            type={ COMPONENTS.INPUT.TYPE.NUMBER }
            field={ AIR_PRICE_FIELDS.BASE }
            value={ price.base }
            onBlur={ onBlur }
            onChange={ onChangeVersionPriceDetailsInput }
          />
          <Input
            label={ LABELS.DETAIL_PRICE.TAX }
            type={ COMPONENTS.INPUT.TYPE.NUMBER }
            field={ AIR_PRICE_FIELDS.TAX }
            value={ price.tax }
            onBlur={ onBlur }
            onChange={ onChangeVersionPriceDetailsInput }
          />
          <Input
            label={ LABELS.DETAIL_PRICE.COMMISSION }
            type={ COMPONENTS.INPUT.TYPE.NUMBER }
            field={ AIR_PRICE_FIELDS.COMMISSION }
            value={ price.commission }
            onBlur={ onBlur }
            onChange={ onChangeVersionPriceDetailsInput }
          />
        </div>
        <div className={ styles.price_row }>
          <Input
            label={ LABELS.DETAIL_PRICE.FEE }
            type={ COMPONENTS.INPUT.TYPE.NUMBER }
            field={ AIR_PRICE_FIELDS.FEE }
            value={ price.fee }
            onBlur={ onBlur }
            onChange={ onChangeVersionPriceDetailsInput }
          />
        </div>
        <div className={ styles.price_row }>
          <Input
            label={ LABELS.DETAIL_PRICE.TAXES }
            type={ COMPONENTS.INPUT.TYPE.NUMBER }
            field={ AIR_PRICE_FIELDS.TAXES }
            value={ price.taxes }
            onBlur={ onBlur }
            onChange={ onChangeVersionPriceDetailsInput }
          />
        </div>
      </div>
    );

    const renderTrainPriceDetails = () => (
      <div className={ styles.price }>
        <p>{ LABELS.TITLE }</p>
        <div className={ styles.price_row }>
          <Input
            label={ LABELS.DETAIL_PRICE.BASE }
            type={ COMPONENTS.INPUT.TYPE.NUMBER }
            field={ TRAIN_PRICE_FIELDS.BASE }
            value={ price.fare + price.tariffPrice }
            onBlur={ onBlur }
            disabled={ true }
            onChange={ onChangeVersionPriceDetailsInput }
          />
          <Input
            label={ LABELS.DETAIL_PRICE.FARE }
            type={ COMPONENTS.INPUT.TYPE.NUMBER }
            field={ TRAIN_PRICE_FIELDS.FARE }
            value={ price.fare }
            onBlur={ onBlur }
            onChange={ onChangeVersionPriceDetailsInput }
          />
          <Input
            label={ LABELS.DETAIL_PRICE.TARIFF_PRICE }
            type={ COMPONENTS.INPUT.TYPE.NUMBER }
            field={ TRAIN_PRICE_FIELDS.TARIFF_PRICE }
            value={ price.tariffPrice }
            onBlur={ onBlur }
            onChange={ onChangeVersionPriceDetailsInput }
          />
        </div>
        <div className={ styles.price_row }>
          <Input
            label={ LABELS.DETAIL_PRICE.FEE }
            type={ COMPONENTS.INPUT.TYPE.NUMBER }
            field={ TRAIN_PRICE_FIELDS.FEE }
            disabled={ true }
            value={ price.fee }
            onBlur={ onBlur }
            onChange={ onChangeVersionPriceDetailsInput }
          />
          <Input
            label={ LABELS.DETAIL_PRICE.COMMISSION }
            type={ COMPONENTS.INPUT.TYPE.NUMBER }
            field={ TRAIN_PRICE_FIELDS.COMMISSION }
            value={ price.commission }
            onBlur={ onBlur }
            onChange={ onChangeVersionPriceDetailsInput }
          />
          <Input
            field={ TRAIN_PRICE_FIELDS.PROVIDER_COMMISSION }
            type={ COMPONENTS.INPUT.TYPE.NUMBER }
            label={ LABELS.DETAIL_PRICE.PROVIDER_COMMISSION }
            value={ price.providerCommission }
            onBlur={ onBlur }
            onChange={ onChangeVersionPriceDetailsInput }
          />
        </div>
      </div>
    );

    return serviceType === SERVICETYPE.AIR
      ? renderAirlinePriceDetails()
      : renderTrainPriceDetails();
  };

OperationDetailPrice.propTypes = {
  price: PropTypes.object.isRequired,
  onChangeVersionPriceDetailsInput: PropTypes.func.isRequired,
  serviceType: PropTypes.string.isRequired,
  onBlur: PropTypes.func.isRequired,
};
